import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Related from "../sections/landing/popular.js";
import Find from '../sections/nose/nosefind.js';
import NoseContent from '../sections/skinpen/skinpencontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Gallery from "../sections/skinpen/skinpengallery.js";
import Hero from "../sections/skinpen/skinpenhero.js";
import Glance from "../sections/skinpen/skinpenglance.js";
import Testimonial from "../sections/index/indexreviews.js";
import Extra from "../sections/micro/mextra.js";
import Nosefaq from "../sections/skinpen/skinpenfaq.js"
import Saveface from "../sections/landing/saveface";
import Steps from '../sections/skinpen/skinpensteps.js';
import NoseBlog from "../sections/nose/noseblog.js";
import Video from "../sections/nose/nosevideo.js";

const MicroPage = () => ( 
  <>
 <Head title="Skinpen® Microneedling in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Skinpen® Microneedling, Microneedling, Microneedling London, Microneedling harley street, microneedling near me, microneedling UK"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <Hero />
      <Glance />
      <Gallery />
      <NoseContent />
  
      <Testimonial />
      <Extra />
    
     
      <Steps />
      <Nosefaq />
      <Related />
      <Find />
      <Saveface />
      
 
     </PageWrapper>
 
  </>
)
export default MicroPage
